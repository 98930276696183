<!--
 * @Description: 热门兼职
 * @Author: huacong
 * @Date: 2021-07-06 14:51:25
 * @LastEditTime: 2021-07-09 11:20:04
 * @LastEditors: huacong
-->
<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="12" v-for="item in partData" :key="item.id">
        <div class="resumebox" @click="goResume(item)">
          <el-row>
            <el-col :span="12" class="infobox fontHover">
              {{ item.j_certificate }}-{{ item.j_major }}
            </el-col>
            <el-col :span="12" class="text-right">
              {{ item.com_external_name }}
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12" class="infobox font-red" style="font-size:16px">
              {{ item.j_min_price }}K-{{ item.j_max_price }}K
            </el-col>
            <el-col :span="12" class="text-right spanList">
              <span>{{ item.com_nature }}</span>
              <span>{{ item.com_size }}</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12" class="spanList">
              <span>{{ item.j_province }}-{{ item.j_city }}</span>
              <span>{{ item.j_work_experience }}</span>
              <span>{{ item.j_education_need }}</span>
            </el-col>
            <el-col :span="12" class="text-right">
              {{ item.create_time }}
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "urgentPartTime",
  data() {
    return {
      partData: [],
      searchInfo: {},
    };
  },
  computed: {
    ...mapState(["talcer3", "talmajor3"]),
  },
  created() {
    // this.getParttimeList();
  },
  methods: {
    ...mapMutations(["changeTalcer3", "changeTalmajor3"]),
    // 获取人员全职列表
    getParttimeList() {
      let talcer3 = this.$route.query.cer;
      let talmajor3 = this.$route.query.major;
      this.searchInfo.page = 1;
      this.searchInfo.limit = 10;
      this.searchInfo.j_certificate = talcer3;
      this.searchInfo.j_major = talmajor3;
      this.$http.post("index/fulljob/getlist", this.searchInfo).then((res) => {
        if (res.data.resultCode === 1) {
          this.partData = res.data.resultData.list;
        }
      });
    },
    goResume(item) {
      // // 推荐证书
      // this.changeTalcer3({
      //   talcer3: item.j_certificate,
      // });
      // // 推荐专业
      // this.changeTalmajor3({
      //   talmajor3: item.j_major,
      // });
      let routeUrl = this.$router.resolve({
        name: "fullTimeInfo",
        query: { id: item.id, cer: item.j_certificate, major: item.j_major },
      });
      window.open(routeUrl.href, "_blank");
    },
    goPartTime() {
      this.$router.push({
        name: "fullTimeIndex",
        params: { cer: this.talcer3, major: this.talmajor3 },
      });
    },
  },
  mounted() {
    this.getParttimeList();
  },
};
</script>
<style scoped>
.infobox {
  font-size: 17px;
  font-weight: bold;
  padding-bottom: 12px;
}
.resumebox {
  height: 80px;
  padding: 20px;
  background: #dbe6f7;
  border-radius: 4px;
  margin-bottom: 20px;
}
.resumebox:hover {
  cursor: pointer;
  box-shadow: 0px -1px 0px 0px #e8e8e8;
}
.spanList span:not(:last-child) {
  margin-right: 8px;
}
</style>
